<template>
  <div id="terminal_settings_component">
    <div class="option_content_container">
      <div class="option_content_container__item option_template_container">
        <div class="option_template_container__item title">
          <label>Шаблоны</label>
        </div>
        <div class="option_template_container__item action_bar">
          <action-bar
            :show="actionBarData.template.show"
            @close-action-bar="resetActionBar('template')"
            :status="actionBarData.template.status"
            :action="actionBarData.template.action"
            :broadDescription="actionBarData.template.broadDescription"
          />
        </div>

        <div
          class="option_template_container__item input_contaner select_input template_input"
        >
          <div class="input_contaner__item input_title">Текущий шаблон</div>
          <div class="input_contaner__item input_select">
            <search-select
              :options="templates"
              v-model:selected="selectedTemplate"
              style="width: 400px"
            />
          </div>
        </div>
        <div class="option_template_container__item button">
          <button
            v-if="
              selectedTemplate !== terminal.template &&
              selectedTemplate !== null
            "
            @click="onApplyTemplate(terminal.terminalId)"
            class="blue_button"
          >
            Применить
          </button>
          <button v-else class="gray_button">Применить</button>
        </div>
      </div>
      <div class="option_content_container__item option_distributive_container">
        <div class="option_distributive_container__item title">
          <label>Дистрибутивы</label>
        </div>
        <div class="option_distributive_container__item action_bar">
          <action-bar
            :show="actionBarData.distributive.show"
            @close-action-bar="resetActionBar('distributive')"
            :status="actionBarData.distributive.status"
            :action="actionBarData.distributive.action"
            :broadDescription="actionBarData.distributive.broadDescription"
          />
        </div>
        <div
          class="option_distributive_container__item distributive_input_contaner"
        >
          <div
            class="distributive_input_contaner__item input_contaner select_input distributive_type_input"
          >
            <div class="input_contaner__item input_title">Тип дистрибутива</div>
            <div class="input_contaner__item input_select">
              <search-select
                @change="onChangeDistributivesType"
                :options="unpackDistributives"
                v-model:selected="selectedDistributivesType"
                style="width: 150px"
              />
            </div>
          </div>
          <div
            class="distributive_input_contaner__item input_contaner select_input distributive_build_input"
          >
            <div class="input_contaner__item input_title">Версия</div>
            <div class="input_contaner__item input_select">
              <search-select
                :options="ditributiveVersions"
                v-model:selected="selectedDistributivesVersion"
                :defaultValue="defaultDistributives[selectedDistributivesType]"
                style="width: 400px"
              />
              <!-- <select v-model="selectedDistributivesVersion">
                <option
                  v-for="(el, i) in ditributiveVersions"
                  :key="i"
                  :value="el"
                >
                  {{ defaultDistributives[selectedDistributivesType] == el ? `${el} (default)` : el }}
                </option>
              </select> -->
            </div>
          </div>
        </div>
        <div
          class="option_distributive_container__item input_contaner select_input"
        ></div>
        <div
          class="option_template_container__item distributive_buttons_container"
        >
          <div class="distributive_buttons_container__item apply">
            <button
              v-if="
                selectedDistributivesVersion &&
                selectedDistributivesVersion !== getCurrentDistrVersionByType()
              "
              @click="onApplyDistributive"
              class="blue_button"
            >
              Применить
            </button>
            <button v-else class="gray_button">Применить</button>
          </div>
          <div class="distributive_buttons_container__item update">
            <button @click="onUpdateDistributive" class="blue_button">
              Обновить
            </button>
          </div>
          <div class="distributive_buttons_container__item delete">
            <lock-button
              :text="'Cбросить'"
              callback="reset"
              @reset="onResetDistributive"
            />
            <!-- <button @click="onUpdateDistributive" class="blue_button">Удалить</button> -->
          </div>
        </div>
      </div>
      <div class="option_content_container__item option_config_container">
        <div class="option_config_container__item title">
          <label>Конфигурации</label>
        </div>
        <div class="option_config_container__item action_bar">
          <action-bar
            :show="actionBarData.displayConfig.show"
            @close-action-bar="resetActionBar('displayConfig')"
            :status="actionBarData.displayConfig.status"
            :action="actionBarData.displayConfig.action"
            :broadDescription="actionBarData.displayConfig.broadDescription"
          />
        </div>
        <div class="option_config_container__item config_input_container">
          <div
            class="config_input_container__item config_input_line_container reset_display_config"
          >
            <div class="config_input_line_container__item label_input">
              <label>Конфигурация дисплеев</label>
            </div>

            <div class="config_input_line_container__item">
              <lock-button
                :text="'Очистить'"
                callback="reset"
                @reset="onClearDisplayConfig"
              />
            </div>
          </div>
          <div
            class="config_input_container__item config_input_line_container reset_device_config"
          >
            <div class="config_input_line_container__item label_input">
              <label>Конфигурация устройств</label>
            </div>
            <div class="config_input_line_container__item">
              <lock-button
                :text="'Очистить'"
                callback="reset"
                @reset="onClearDeviceConfig"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="option_content_container__item option_transport_container">
        <div class="option_transport_container__item title">
          <label>Транспорт</label>
        </div>

        <div class="option_transport_container__item action_bar">
          <action-bar
            :show="actionBarData.trasportRule.show"
            @close-action-bar="resetActionBar('trasportRule')"
            :status="actionBarData.trasportRule.status"
            :action="actionBarData.trasportRule.action"
            :broadDescription="actionBarData.trasportRule.broadDescription"
          />
        </div>

        <div class="option_transport_container__item transport_input_container">
          <div
            class="transport_input_container__item input_contaner select_input trasport_input"
          >
            <div class="input_contaner__item input_title">
              Назначенный агент
            </div>
            <div class="input_contaner__item input_select">
              <search-select
                :options="trasportRules"
                v-model:selected="selectedTransportRule"
                style="width: 200px"
              />
            </div>
          </div>

          <div
            class="transport_input_container__item transport_button_container"
          >
            <div class="button_container__item">
              <button
                v-if="
                  selectedTransportRule !== transportCurrentRule &&
                  selectedTransportRule !== null
                "
                @click="onApplyTransportRule()"
                class="blue_button"
              >
                Назначить
              </button>
              <button v-else class="gray_button">Назначить</button>
            </div>
            <div class="button_container__item">
              <lock-button
                v-if="transportCurrentRule"
                :text="'Удалить'"
                callback="reset"
                @reset="onDeleteTransportRule"
              />
              <button v-else class="gray_button">Удалить</button>
            </div>
          </div>
        </div>
      </div>
      <div class="option_content_container__item current_agent_container">
        <div class="current_agent_container__item title">Текущий агент</div>
        <div class="current_agent_container__item action_bar">
          <action-bar
            :show="actionBarData.trasportCurrent.show"
            @close-action-bar="resetActionBar('trasportCurrent')"
            :status="actionBarData.trasportCurrent.status"
            :action="actionBarData.trasportCurrent.action"
            :broadDescription="actionBarData.trasportCurrent.broadDescription"
          />
        </div>
        <div class="current_agent_container__item content_title">
          Текущий агент
        </div>
        <div class="current_agent_container__item label_input">
          <label>{{
            this.terminal.transport.agent
              ? this.terminal.transport.agent
              : "Неизвестно"
          }}</label>
        </div>
        <div class="current_agent_container__item">
          <button
            v-if="terminal.online"
            @click="onRecreateTransport()"
            class="blue_button"
          >
            Пересоздать транспорт
          </button>
          <button v-else class="gray_button">Пересоздать транспорт</button>
        </div>
      </div>
      <div class="option_content_container__item terminal_load_container">
        <div class="terminal_load_container__item title">
          Загрузка терминала
        </div>
        <div class="terminal_load_container__item action_bar">
          <action-bar
            :show="actionBarData.terminalLoad.show"
            @close-action-bar="resetActionBar('terminalLoad')"
            :status="actionBarData.terminalLoad.status"
            :action="actionBarData.terminalLoad.action"
            :broadDescription="actionBarData.terminalLoad.broadDescription"
          />
        </div>
        <div class="terminal_load_container__item options_root_container">
          <div class="options_root_container__item status_line_container">
            <div class="status_line_container__item status_title">Статус</div>
            <div
              class="status_line_container__item status_value"
              :class="`${terminal.terminalState.toLowerCase()}`"
            >
              {{ terminal.terminalState }}
            </div>
          </div>
          <div
            v-if="terminal.terminalState === 'OK'"
            class="options_root_container__item status_ok_container"
          >
            <div
              v-if="terminal.online"
              class="status_ok_container__item line_check_box_container"
            >
              <div class="line_check_box_container__item line_checkbox">
                <check-box v-model:checked="terminalLoad.cleanCache" />
              </div>
              <div class="line_check_box_container__item line_title">
                Очистить кэш
              </div>
            </div>
            <div
              v-if="terminal.online"
              class="status_ok_container__item line_check_box_container"
            >
              <div class="line_check_box_container__item line_checkbox">
                <check-box v-model:checked="terminalLoad.restart" />
              </div>
              <div class="line_check_box_container__item line_title">
                Перезагрузить
              </div>
            </div>
            <div class="status_ok_container__item block_button">
              <lock-button
                :text="'Заблокировать'"
                callback="block"
                @block="onBlockTerminal"
              />
            </div>
          </div>
          <div
            v-else-if="terminal.terminalState == 'BLOCKED'"
            class="options_root_container__item status_blocked_container"
          >
            <div class="status_blocked_container__item block_button">
              <lock-button
                :text="'Разблокировать'"
                callback="unblock"
                @unblock="onUnblockTerminal"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent } from "vue";
export default {
  components: {
    "action-bar": defineAsyncComponent(() =>
      import("@/components/ActionBar.vue")
    ),
    "lock-button": defineAsyncComponent(() =>
      import("@/components/buttons/LockButton.vue")
    ),
    "search-select": defineAsyncComponent(() =>
      import("@/components/SearchSelect.vue")
    ),
    "check-box": defineAsyncComponent(() =>
      import("@/components/CheckBox.vue")
    ),
  },

  props: {
    terminal: {
      type: Object,
    },
  },
  data() {
    return {
      templates: [],
      selectedTemplate: this.terminal.template,
      selectedDistributivesType: "containers",
      selectedDistributivesVersion: "",
      transportCurrentRule: undefined,
      selectedTransportRule: null,
      distributives: {},
      transportAgents: [],
      actionBarData: {
        template: {
          status: "",
          action: "",
          broadDescription: "",
          show: false,
        },
        distributive: {
          status: "",
          action: "",
          broadDescription: "",
          show: false,
        },
        trasportRule: {
          status: "",
          action: "",
          broadDescription: "",
          show: false,
        },
        trasportCurrent: {
          status: "",
          action: "",
          broadDescription: "",
          show: false,
        },
        displayConfig: {
          status: "",
          action: "",
          broadDescription: "",
          show: false,
        },
        terminalLoad: {
          status: "",
          action: "",
          broadDescription: "",
          show: false,
        },
      },
      defaultDistributives: {},
      terminalLoad: {
        cleanCache: false,
        restart: false,
      },
    };
  },
  methods: {
    // reset action bar state
    resetActionBar(type) {
      this.actionBarData[type] = {
        status: "",
        action: "",
        broadDescription: "",
        show: false,
      };
    },

    // update ditr with status
    onUpdateDistributive() {
      this.actionBarData.distributive = {
        status: "WAITING",
        action: "Обновление списка дистрибутивов",
        show: true,
      };
      this.getDistributives().then(([status, data]) => {
        if (status) {
          this.actionBarData.distributive.status = "OK";
          setTimeout(() => {
            this.resetActionBar("distributive");
          }, 4000);
        } else {
          this.actionBarData.distributive.status = "ERROR";
          this.actionBarData.distributive.broadDescription = data;
        }
      });
    },
    // reset ditr with status
    onResetDistributive() {
      this.actionBarData.distributive = {
        status: "WAITING",
        action: "Сброс дистрибутива",
        show: true,
      };
      this.$http
        .post(`${this.$backEndUrl}/v1/proxy/api`, {
          url: `/v2/terminal/distributions?terminalId=${this.terminal.terminalId}&distrTypes=${this.selectedDistributivesType}`,
          method: "DELETE",
          payload: {},
          headers: {},
        })
        .then((res) => {
          console.log(
            "Response from proxy api obtained (reset distr for terminal)",
            res
          );

          switch (res.data.statusCode) {
            case 200:
              this.actionBarData.distributive.status = "OK";
              this.$emit("updateTerminalData", this.terminal);
              setTimeout(() => {
                this.resetActionBar("distributive");
              }, 4000);
              break;

            case 403:
              this.actionBarData.distributive.status = "FORBIDDEN";
              break;

            default:
              this.actionBarData.distributive.status = "ERROR";
              this.actionBarData.distributive.broadDescription =
                res.data.payload;
              break;
          }
        })
        .catch((error) => {
          if (error.request.status === 403) {
            this.actionBarData.distributive.status = "FORBIDDEN";
            return false;
          }
          this.actionBarData.distributive.broadDescription = error.data;
          console.log("Error to reset distr for to terminal", error);
        });
    },
    // onDeleteTransportRule
    onDeleteTransportRule() {
      this.actionBarData.trasportRule = {
        status: "WAITING",
        action: "Удаление правила",
        show: true,
      };
      this.$http
        .post(`${this.$backEndUrl}/v1/proxy/api`, {
          url: `/v2/transport/terminals/rules`,
          method: "POST",
          payload: {
            agent: this.selectedTransportRule,
            terminalIds: [this.terminal.terminalId],
            action: "DELETE",
          },
          headers: {},
        })
        .then((res) => {
          console.log(
            "Response from proxy api obtained (delete trasport rule for terminal)",
            res
          );

          switch (res.data.statusCode) {
            case 200:
              this.transportCurrentRule = undefined;
              this.actionBarData.trasportRule.status = "OK";
              setTimeout(() => {
                this.resetActionBar("trasportRule");
              }, 4000);
              break;

            case 403:
              this.actionBarData.trasportRule.status = "FORBIDDEN";
              break;

            default:
              this.actionBarData.trasportRule.status = "ERROR";
              this.actionBarData.trasportRule.broadDescription =
                res.data.payload;
              break;
          }
        })
        .catch((error) => {
          if (error.request.status === 403) {
            this.actionBarData.trasportRule.status = "FORBIDDEN";
            return false;
          }

          this.actionBarData.trasportRule.status = "ERROR";
          this.actionBarData.trasportRule.broadDescription = error.data;
          console.log("Error to delete trasport rule for terminal", error);
        });
    },

    // apply trasport rule
    onApplyTransportRule() {
      this.actionBarData.trasportRule = {
        status: "WAITING",
        action: "Cоздание правила",
        show: true,
      };
      this.$http
        .post(`${this.$backEndUrl}/v1/proxy/api`, {
          url: `/v2/transport/terminals/rules`,
          method: "POST",
          payload: {
            agent: this.selectedTransportRule,
            terminalIds: [this.terminal.terminalId],
            action: "SET",
          },
          headers: {},
        })
        .then((res) => {
          console.log(
            "Response from proxy api obtained (set trasport rule to terminal)",
            res
          );

          switch (res.data.statusCode) {
            case 200:
              this.transportCurrentRule = this.selectedTransportRule;
              this.actionBarData.trasportRule.status = "OK";
              setTimeout(() => {
                this.resetActionBar("trasportRule");
              }, 4000);
              break;

            case 403:
              this.actionBarData.trasportRule.status = "FORBIDDEN";
              break;

            default:
              this.actionBarData.trasportRule.status = "ERROR";
              this.actionBarData.trasportRule.broadDescription =
                res.data.payload;
              break;
          }
        })
        .catch((error) => {
          if (error.request.status === 403) {
            this.actionBarData.trasportRule.status = "FORBIDDEN";
            return false;
          }
          this.actionBarData.trasportRule.status = "ERROR";
          this.actionBarData.trasportRule.broadDescription = error.data;
          console.log("Error to set trasport rule to terminal", error);
        });
    },

    // recreate trasport
    onRecreateTransport() {
      this.actionBarData.trasportCurrent = {
        status: "WAITING",
        action: "Пересоздание транспорта",
        show: true,
      };
      this.$http
        .post(`${this.$backEndUrl}/v1/proxy/api`, {
          url: `/v2/terminal/transport?terminalId=${this.terminal.terminalId}`,
          method: "DELETE",
          payload: {},
          headers: {},
        })
        .then((res) => {
          console.log(
            "Response from proxy api obtained (recreate trasport for terminal)",
            res
          );

          switch (res.data.statusCode) {
            case 200:
              this.actionBarData.trasportCurrent.status = "OK";
              setTimeout(() => {
                this.resetActionBar("trasportCurrent");
              }, 4000);
              break;

            case 403:
              this.actionBarData.trasportCurrent.status = "FORBIDDEN";
              break;

            default:
              this.actionBarData.trasportCurrent.status = "ERROR";
              this.actionBarData.trasportCurrent.broadDescription =
                res.data.payload;
              break;
          }
        })
        .catch((error) => {
          if (error.request.status === 403) {
            this.actionBarData.trasportCurrent.status = "FORBIDDEN";
            return false;
          }
          this.actionBarData.trasportCurrent.status = "ERROR";
          this.actionBarData.trasportCurrent.broadDescription = error.data;
          console.log("Error to recreate trasport for terminal", error);
        });
    },

    // clear device config
    onClearDeviceConfig() {
      this.actionBarData.displayConfig = {
        status: "WAITING",
        action: "Удаление конфигурации устройств",
        show: true,
      };
      this.$http
        .post(`${this.$backEndUrl}/v1/proxy/api`, {
          url: `/v2/terminal/hardware/config?configType=devices&terminalId=${this.terminal.terminalId}`,
          method: "DELETE",
          payload: {},
          headers: {},
        })
        .then((res) => {
          console.log(
            "Response from proxy api obtained (delete device config for terminal)",
            res
          );

          switch (res.data.statusCode) {
            case 200:
              this.actionBarData.displayConfig.status = "OK";
              setTimeout(() => {
                this.resetActionBar("displayConfig");
              }, 4000);
              break;

            case 403:
              this.actionBarData.trasportCurrent.status = "FORBIDDEN";
              break;

            default:
              this.actionBarData.displayConfig.status = "ERROR";
              this.actionBarData.displayConfig.broadDescription =
                res.data.payload;
              break;
          }
        })
        .catch((error) => {
          if (error.request.status === 403) {
            this.actionBarData.displayConfig.status = "FORBIDDEN";
            return false;
          }
          this.actionBarData.displayConfig.status = "ERROR";
          this.actionBarData.displayConfig.broadDescription = error.data;
          console.log("Error to delete device config for terminal", error);
        });
    },

    // clear display config
    onClearDisplayConfig() {
      this.actionBarData.displayConfig = {
        status: "WAITING",
        action: "Удаление конфигурации дисплея",
        show: true,
      };
      this.$http
        .post(`${this.$backEndUrl}/v1/proxy/api`, {
          url: `/v2/terminal/hardware/config?configType=displays&terminalId=${this.terminal.terminalId}`,
          method: "DELETE",
          payload: {},
          headers: {},
        })
        .then((res) => {
          console.log(
            "Response from proxy api obtained (delete display config for terminal)",
            res
          );

          switch (res.data.statusCode) {
            case 200:
              this.actionBarData.displayConfig.status = "OK";
              setTimeout(() => {
                this.resetActionBar("displayConfig");
              }, 4000);
              break;

            case 403:
              this.actionBarData.displayConfig.status = "FORBIDDEN";
              break;

            default:
              this.actionBarData.displayConfig.status = "ERROR";
              this.actionBarData.displayConfig.broadDescription =
                res.data.payload;
              break;
          }
        })
        .catch((error) => {
          if (error.request.status === 403) {
            this.actionBarData.displayConfig.status = "FORBIDDEN";
            return false;
          }
          this.actionBarData.displayConfig.status = "ERROR";
          this.actionBarData.displayConfig.broadDescription = error.data;
          console.log("Error to delete display config for terminal", error);
        });
    },

    // change distributive request
    onApplyDistributive() {
      this.actionBarData.distributive = {
        status: "WAITING",
        action: "Установка дистрибутива",
        show: true,
      };
      this.$http
        .post(`${this.$backEndUrl}/v1/proxy/api`, {
          url: `/v2/terminal/distributions`,
          method: "POST",
          payload: {
            distributions: [this.selectedDistributivesVersion],
            terminalId: this.terminal.terminalId,
          },
          headers: {},
        })
        .then((res) => {
          console.log(
            "Response from proxy api obtained (set dist to terminal)",
            res
          );

          switch (res.data.statusCode) {
            case 200:
              this.$emit("updateTerminalData");
              this.actionBarData.distributive.status = "OK";
              setTimeout(() => {
                this.resetActionBar("distributive");
              }, 4000);
              break;

            case 403:
              this.actionBarData.distributive.status = "FORBIDDEN";
              break;

            default:
              this.actionBarData.distributive.status = "ERROR";
              this.actionBarData.distributive.broadDescription =
                res.data.payload;
              break;
          }
        })
        .catch((error) => {
          if (error.request.status === 403) {
            this.actionBarData.distributive.status = "FORBIDDEN";
            return false;
          }
          this.actionBarData.distributive.status = "ERROR";
          this.actionBarData.distributive.broadDescription = error.data;
          console.log("Error to get terminal list", error);
        });
    },

    // change template request
    onApplyTemplate() {
      this.actionBarData.template = {
        status: "WAITING",
        action: "Установка шаблона",
        show: true,
      };
      this.$http
        .post(`${this.$backEndUrl}/v1/proxy/api`, {
          url: `/v2/terminal/template`,
          method: "POST",
          payload: {
            template: this.selectedTemplate,
            terminalId: this.terminal.terminalId,
          },
          headers: {},
        })
        .then((res) => {
          console.log(
            "Response from proxy api obtained (set template to terminal)",
            res
          );

          switch (res.data.statusCode) {
            case 200:
              this.$emit("updateTerminalData");
              this.actionBarData.template.status = "OK";
              setTimeout(() => {
                this.resetActionBar("template");
              }, 4000);
              break;

            case 403:
              this.actionBarData.template.status = "FORBIDDEN";
              break;

            default:
              this.actionBarData.template.status = "ERROR";
              this.actionBarData.template.broadDescription = res.data.payload;
              break;
          }
        })
        .catch((error) => {
          if (error.request.status === 403) {
            this.actionBarData.template.status = "FORBIDDEN";
            return false;
          }
          this.actionBarData.template.status = "ERROR";
          this.actionBarData.template.broadDescription = error.data;
          console.log("Error to get terminal list", error);
        });
      // .finally(() => {
      //   this.showLoad = false;
      // });
    },

    async onBlockTerminal() {
      // reset cache
      if (this.terminalLoad.cleanCache) {
        if (!(await this.resetTerminalCache())) {
          return;
        }
      }
      // set block
      if (!(await this.blockTerminal())) {
        return;
      }

      // restart
      if (this.terminalLoad.restart) {
        if (!(await this.terminalRestart())) {
          return;
        }
      }

      this.actionBarData.terminalLoad = {
        status: "OK",
        action: "Терминал заблокирован",
        show: true,
      };

      setTimeout(() => {
        this.$emit("updateTerminalData", this.terminal.terminalId);
      }, 100);
      setTimeout(() => {
        this.resetActionBar("terminalLoad");
      }, 4000);
    },

    onUnblockTerminal() {
      this.unBlockTerminal();
      setTimeout(() => {
        this.$emit("updateTerminalData", this.terminal.terminalId);
      }, 100);
    },

    async terminalRestart() {
      this.actionBarData.terminalLoad = {
        status: "WAITING",
        action: "Restarting",
        show: true,
      };
      return await this.$http
        .post(`${this.$backEndUrl}/v1/proxy/api`, {
          url: `/v2/terminal/reset?terminalId=${this.terminal.terminalId}`,
          method: "GET",
          payload: {},
          headers: {},
        })
        .then((res) => {
          console.log(
            "Response from proxy api obtained (restart terminal)",
            res
          );

          switch (res.data.statusCode) {
            case 200:
              this.actionBarData.terminalLoad.status = "OK";
              return true;

            case 403:
              this.actionBarData.terminalLoad.status = "FORBIDDEN";
              return false;

            default:
              this.actionBarData.terminalLoad.broadDescription = JSON.stringify(
                res.data.payload
              );
              return false;
          }
        })
        .catch((error) => {
          if (error.request.status === 403) {
            this.actionBarData.terminalLoad.status = "FORBIDDEN";
            return false;
          }
          this.actionBarData.terminalLoad.status = "ERROR";
          this.actionBarData.terminalLoad.broadDescription = error.data;
          console.log("Error to restart terminal", error);
          return false;
        });
    },

    async blockTerminal() {
      this.actionBarData.terminalLoad = {
        status: "WAITING",
        action: "Blocking",
        show: true,
      };
      return await this.$http
        .post(`${this.$backEndUrl}/v1/proxy/api`, {
          url: `/v2/terminals/block?id=${this.terminal.terminalId}`,
          method: "PUT",
          payload: {},
          headers: {},
        })
        .then((res) => {
          console.log(
            "Response from proxy api obtained (set terminal block)",
            res
          );

          switch (res.data.statusCode) {
            case 200:
              this.actionBarData.terminalLoad.status = "OK";
              return true;

            case 403:
              this.actionBarData.terminalLoad.status = "FORBIDDEN";
              return false;

            default:
              this.actionBarData.terminalLoad.broadDescription = JSON.stringify(
                res.data.payload
              );
              return false;
          }
        })
        .catch((error) => {
          if (error.request.status === 403) {
            this.actionBarData.terminalLoad.status = "FORBIDDEN";
            return false;
          }
          this.actionBarData.terminalLoad.status = "ERROR";
          this.actionBarData.terminalLoad.broadDescription = error.data;
          console.log("Error to reset terminal cache", error);
          return false;
        });
    },

    async unBlockTerminal() {
      this.actionBarData.terminalLoad = {
        status: "WAITING",
        action: "Unblock",
        show: true,
      };
      return await this.$http
        .post(`${this.$backEndUrl}/v1/proxy/api`, {
          url: `/v2/terminals/unblock?id=${this.terminal.terminalId}`,
          method: "PUT",
          payload: {},
          headers: {},
        })
        .then((res) => {
          console.log(
            "Response from proxy api obtained (unblock terminal)",
            res
          );

          switch (res.data.statusCode) {
            case 200:
              this.actionBarData.terminalLoad.status = "OK";
              return true;

            case 403:
              this.actionBarData.terminalLoad.status = "FORBIDDEN";
              return false;

            default:
              this.actionBarData.terminalLoad.broadDescription = JSON.stringify(
                res.data.payload
              );
              return false;
          }
        })
        .catch((error) => {
          if (error.request.status === 403) {
            this.actionBarData.terminalLoad.status = "FORBIDDEN";
            return false;
          }
          this.actionBarData.terminalLoad.status = "ERROR";
          this.actionBarData.terminalLoad.broadDescription = error.data;
          console.log("Error to unblock cache", error);
          return false;
        });
    },

    async resetTerminalCache() {
      this.actionBarData.terminalLoad = {
        status: "WAITING",
        action: "Сброс кеша",
        show: true,
      };
      return await this.$http
        .post(`${this.$backEndUrl}/v1/proxy/api`, {
          url: `/v2/terminal/clearCache?terminalId=${this.terminal.terminalId}`,
          method: "GET",
          payload: {},
          headers: {},
        })
        .then((res) => {
          console.log(
            "Response from proxy api obtained (reset terminal cache)",
            res
          );
          switch (res.data.statusCode) {
            case 200:
              this.actionBarData.terminalLoad.status = "OK";
              return true;

            case 403:
              this.actionBarData.terminalLoad.status = "FORBIDDEN";
              return false;

            default:
              this.actionBarData.terminalLoad.broadDescription = JSON.stringify(
                res.data.payload
              );
              return false;
          }
        })
        .catch((error) => {
          if (error.request.status === 403) {
            this.actionBarData.terminalLoad.status = "FORBIDDEN";
            return false;
          }
          this.actionBarData.terminalLoad.status = "ERROR";
          this.actionBarData.terminalLoad.broadDescription = error.data;
          console.log("Error to reset terminal cache", error);
          return false;
        });
    },

    // unpack current distr version by type
    getCurrentDistrVersionByType() {
      let tempList = this.terminal.distributions.find(
        (el) => el.type === this.selectedDistributivesType
      );
      return tempList ? tempList.distribution : null;
    },

    // action when changes distr types
    onChangeDistributivesType() {
      this.selectedDistributivesVersion = this.getCurrentDistrVersionByType();
    },

    // get distrs request
    getTransport() {
      this.$http
        .post(`${this.$backEndUrl}/v1/proxy/api`, {
          url: `/v2/transport/agents`,
          method: "GET",
          payload: {},
          headers: {},
        })
        .then((res) => {
          console.log(
            "Response from proxy api obtained (transport agent list received)",
            res
          );
          if (res.status === 200 && res.data.statusCode === 200) {
            this.transportAgents = res.data.payload.agents.map((el) => {
              return el.agent;
            });

            this.selectedAgent =
              this.transportAgents.length > 0 ? this.transportAgents[0] : "";
          }
        })
        .catch((error) => {
          console.log("Error to get transport agent list", error);
        });
    },

    // get distrs request
    getTransportRule() {
      this.$http
        .post(`${this.$backEndUrl}/v1/proxy/api`, {
          url: `/v2/transport/terminals/rules`,
          method: "GET",
          payload: {},
          headers: {},
        })
        .then((res) => {
          console.log(
            "Response from proxy api obtained (transport rule received)",
            res
          );
          if (res.status === 200 && res.data.statusCode === 200) {
            const rule = res.data.payload.terminalRules.filter((el) => {
              return el.terminalId === this.terminal.terminalId;
            });
            if (rule.length > 0) {
              this.transportCurrentRule = rule[0].agent;
              this.selectedTransportRule = rule[0].agent;
            }
          }
        })
        .catch((error) => {
          console.log("Error to get transport rule", error);
        });
    },

    // get distrs request
    getDistributives() {
      return this.$http
        .post(`${this.$backEndUrl}/v1/proxy/api`, {
          url: `/v2/distributions/types`,
          method: "GET",
          payload: {},
          headers: {},
        })
        .then((res) => {
          console.log(
            "Response from proxy api obtained (distr list received)",
            res
          );
          if (res.status === 200 && res.data.statusCode === 200) {
            res.data.payload.loadingDistrTypes.forEach((el) => {
              this.distributives[el.distributionsType] = el.distributions;
              this.defaultDistributives[el.distributionsType] =
                el.defaultDistribution;
            });
            return [true, null];
          }
          return [false, res.data];
        })
        .catch((error) => {
          console.log("Error to get ditr list", error);
          return [false, error.data];
        });
    },

    // get templates
    getTemplates() {
      this.$http
        .post(`${this.$backEndUrl}/v1/proxy/api`, {
          url: `/v2/templates`,
          method: "GET",
          payload: {},
          headers: {},
        })
        .then((res) => {
          console.log(
            "Response from proxy api obtained (templates list received)",
            res
          );
          if (res.status === 200) {
            this.templates = res.data.payload.templates
              .map((el) => el.template)
              .sort((a, b) => {
                let x = a.toLowerCase();
                let y = b.toLowerCase();
                if (x > y) {
                  return 1;
                }
                if (x < y) {
                  return -1;
                }
                return 0;
              });
          }
        })
        .catch((error) => {
          console.log("Error to get terminal list", error);
        });
      // .finally(() => {
      //   this.showLoad = false;
      // });
    },
  },

  computed: {
    // compute agent list in case when current rule not present in list of agents from api
    trasportRules() {
      if (
        this.transportCurrentRule &&
        !this.transportAgents.includes(this.transportCurrentRule)
      ) {
        return [this.transportCurrentRule, ...this.transportAgents];
      }
      return this.transportAgents;
    },

    unpackDistributives() {
      return Object.keys(this.distributives);
    },
    // compute distr versions in case when current distr version not present in list of distr version from api
    ditributiveVersions() {
      // prevent error during async load
      if (!this.distributives[this.selectedDistributivesType]) {
        return [];
      }
      const version = this.getCurrentDistrVersionByType();
      const ifCurrentVersionPresent =
        this.distributives[this.selectedDistributivesType].includes(version);
      if (!ifCurrentVersionPresent) {
        return [version, ...this.distributives[this.selectedDistributivesType]];
      }
      return this.distributives[this.selectedDistributivesType];
    },
  },

  created() {
    this.getTemplates();
    this.getDistributives();
    this.selectedDistributivesVersion = this.getCurrentDistrVersionByType();
    this.getTransport();
    this.getTransportRule();
  },

  watch: {
    "terminal.distributions": function () {
      this.getDistributives();
      this.selectedDistributivesVersion = this.getCurrentDistrVersionByType();
    },
  },
};
</script>

<style lang="less">
@import "../assets/styles/inputs.less";
@import "../assets/styles/buttons.less";

.option_content_container {
  display: flex;
  flex-direction: column;
  .action_bar {
    min-height: 20px;
    max-height: 100px;
    margin-bottom: 5px;
  }

  .option_content_container__item {
    margin-bottom: 25px;
  }
  .title {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    margin-bottom: 5px;
  }

  .input_contaner {
    display: flex;
    flex-direction: column;

    .input_title {
      font-weight: 400;
      font-size: 10px;
      line-height: 12px;
      color: #a1a3ab;
      margin-bottom: 3px;
    }

    .input_select {
      margin-bottom: 15px;
      margin-right: 10px;
      select {
        width: 100%;
        margin-left: 0px;
        margin-top: 0px;
        color: #010101;
      }
    }
  }
}

.option_template_container {
  display: flex;
  flex-direction: column;

  .template_input {
    width: 244px;
  }
}
.distributive_buttons_container {
  display: flex;
  .distributive_buttons_container__item {
    margin-right: 10px;
  }
}

.distributive_input_contaner {
  display: flex;
  align-items: center;

  button {
    all: unset;
  }

  .distributive_type_input {
    width: 180px;
  }

  .distributive_build_input {
    width: 371px;
  }
}

.option_transport_container {
  display: flex;
  flex-direction: column;

  .trasport_input {
    width: 244px;
  }
}

.transport_input_container {
  display: flex;
  flex-direction: column;
}

.transport_button_container {
  display: flex;

  .button_container__item {
    margin-right: 15px;
  }
}

.current_agent_container {
  .content_title {
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    color: #a1a3ab;
    margin-bottom: 3px;
  }

  .label_input {
    width: 244px;
    margin-bottom: 15px;
  }
}

.config_input_line_container {
  display: flex;
  align-items: center;

  .label_input {
    width: 180px;
    margin-right: 10px;
  }
  &.reset_device_config {
    margin-top: 10px;
  }
}

.terminal_load_container {
  display: flex;
  flex-direction: column;
  .options_root_container {
    display: flex;
    flex-direction: column;
    font-size: 0.95em;

    .status_line_container {
      display: flex;
      flex-direction: row;
      margin-bottom: 5px;
      .status_title {
        margin-right: 20px;
      }
      .status_value {
        &.ok {
          color: #5bc367;
        }
        &.blocked {
          color: #f44c4b;
        }
        &.waiting {
          color: #f44c4b;
        }
      }
    }

    .block_button {
      width: 150px;
      margin-top: 10px;
    }

    .status_ok_container {
      display: flex;
      flex-direction: column;

      .line_check_box_container {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: 5px;
        .line_checkbox {
          margin-right: 5px;
        }
      }
    }

    // .status_blocked_container {

    // }
  }
}
</style>
